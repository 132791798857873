@font-face {
	font-family: proxima-nova;
	src: url(../../fonts/ProximaNova-Regular.otf);
	font-weight: 400;
}

@font-face {
	font-family: proxima-nova;
	src: url(../../fonts/proxima-nova-bold-59515b1374619.otf);
	font-weight: 700;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: proxima-nova, sans-serif;
	transition: all 0.3s ease-out;
}
*:focus {
	outline: none;
}
html,
body,
#root {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6,
a {
	font-size: inherit;
	font-weight: inherit;
	margin: 0;
	padding: 0;
}
ul,
ol,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}
a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
	color: inherit;
}
button {
	cursor: pointer;
	border: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
input[type='text'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
}
